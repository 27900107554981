// @flow
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import logo from '../assets/images/logo-70@2x.png';

export default () => (
  <section className="module-header not-found">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <GatsbyLink to="/">
            <img src={logo} alt="" />
          </GatsbyLink>
          <p>404 - Not Found</p>
        </div>
      </div>
    </div>
  </section>
);
